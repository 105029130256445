<template>
    <modal :show="show">
        <div class="modal-content">
            <div class="modal-header">

                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">
                    Sign Up
                </h6>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-sm-12">
                        <label>{{ $t('AddSignUp.UserEmail') }} :<span class="text-danger"> *</span></label>
                        <div v-bind:class="{ 'has-danger': $v.loginDetails.email.$error }">
                            <input class="form-control" v-model="$v.loginDetails.email.$model"
                                @blur="EmailDuplicate(loginDetails.email)" />
                            <span v-if="$v.loginDetails.email.$error" class="error text-danger">
                                <span v-if="!$v.loginDetails.email.required"> {{
        $t('AddSignUp.RegisterUser_Error_Required_EmailID') }} </span>
                                <span v-if="!$v.loginDetails.email.email"> {{
        $t('AddSignUp.RegisterUser_Error_Format_EmailID') }} </span>
                            </span>
                            <span class="text-right text-danger" v-if="emailExist">{{ $t('AddSignUp.EmailExist')
                                }}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <label> {{ $t('AddSignUp.Password') }} :<span class="text-danger"> *</span></label>
                        <div v-bind:class="{ 'has-danger': $v.loginDetails.password.$error }">

                            <div class="input-group mb-3">
                                <input id="password" v-model="$v.loginDetails.password.$model" name="password"
                                    :type="password1" class="form-control" v-bind:placeholder="$t('AddSignUp.Password')"
                                    aria-label="Recipient's username" aria-describedby="button-addon2">
                                <button class="btn btn-secondary" v-on:mousedown="showPassword1"
                                    @mouseleave="hidepassword1" type="button" id="button-addon2"><i
                                        v-bind:class="eyeValue1 ? 'fa fa-eye-slash' : 'fas fa-eye'"></i></button>
                            </div>
                            <span v-if="$v.loginDetails.password.$error" class="error text-danger">
                                <span v-if="!$v.loginDetails.password.required">{{
        $t('AddSignUp.RegisterUser_Error_Required_Password') }}</span>
                                <span v-if="!$v.loginDetails.password.strongPassword">{{
        $t('AddSignUp.RegisterUser_Error_Format_Password') }}</span>
                            </span>
                        </div>
                    </div>



                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveLoginDetails"
                    v-bind:disabled="$v.loginDetails.$invalid || emailExist" >
                    {{ $t('addBrand.btnSave') }}
                </button>
               
                <button type="button" class="btn btn-soft-secondary btn-sm" v-on:click="close()">
                    {{
                    $t('addBrand.btnClear')
                    }}
                </button>
            </div>
            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
        </div>
    </modal>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import 'vue-loading-overlay/dist/vue-loading.css';
import { required, email } from "vuelidate/lib/validators"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    mixins: [clickMixin],
    props: ['show', 'user', 'type','isEmployee'],
    components: {
        Loading
    },
    data: function () {
        return {
            arabic: '',
            english: '',
            render: 0,
            loading: false,
            eyeValue1: false,
            emailExist: false,
            password: '',
            password1: '',
            loginDetails: {
                id: '00000000-0000-0000-0000-000000000000',
                email: '',
                userName: '',
                firstName: '',
                lastName: '',
                password: '',
                phoneNumber: '',
                employeeId: '',
                temporaryCashReceiver:false,
                temporaryCashIssuer:false,
                isSupervisor:false,
                isActive:true,


            }
        }
    },
    validations: {
        loginDetails: {
            password:
            {
                required,
                strongPassword(password) {
                    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/.test(password);
                }
            },

            email:
            {
                required,
                email
            },

        }
    },
    methods: {
        hidepassword() {
                this.password = "password"
                this.eyeValue = false;
            },
            showPassword1() {

                if (this.password1 === "password") {
                    this.password1 = "text";
                    this.eyeValue1 = true;
                }

            },
            hidepassword1() {

                this.password1 = "password"
                this.eyeValue1 = false;
            },
        close: function () {
            this.$emit('close');
        },
        SaveLoginDetails: function () {

            this.loading = true;
            var root = this;
            var token = '';

            if(this.isEmployee==true)
            {
            this.loginDetails.employeeId=this.user.id;
            this.loginDetails.firstName=this.user.englishName;
            this.loginDetails.phoneNumber=this.user.mobileNo;
            this.loginDetails.userName=this.loginDetails.email;
            }
            else
            {
            this.loginDetails.employeeId=this.user.id;
            this.loginDetails.firstName=this.user.englishName;
            this.loginDetails.phoneNumber=this.user.telephone;
            this.loginDetails.userName=this.loginDetails.email;
            }
          

            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .post('/account/SaveUser', this.loginDetails, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    if (response.data != "Already Exists") {
                        root.loading = false
                        root.info = response.data.bpi


                        root.$swal.fire({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,
                            timer: 1800,
                            timerProgressBar: true,

                        });
                        root.$emit('close');
                    }
                    else {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal.fire({
                            icon: 'error',
                            title: 'UserId Already Exist',
                            showConfirmButton: false,
                            timer: 1800,
                            timerProgressBar: true,

                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Server Error',
                            text: error,
                        });

                        root.loading = false
                })
                .finally(() => root.loading = false)
        },
    

    EmailDuplicate: function (x) {
        var root = this;
        var token = '';
        if (root.$session.exists()) {
            token = localStorage.getItem('token');
        }
        root.emailExist = false;
        this.$https.get('/account/DuplicateEmail?email=' + x, { headers: { "Authorization": `Bearer ${token}` } })
            .then(function (response) {
                if (response.data == true) {
                    root.emailExist = true;
                }
                else {
                    root.emailExist = false;
                }


            })
    },
},
mounted: function () {
    this.loginDetails.email = this.user.email;
    if(this.isEmployee==true)
    {
        this.loginDetails.temporaryCashReceiver=true;
    }
    else
    {
        this.loginDetails.temporaryCashReceiver=false;

        this.loginDetails.temporaryCashIssuer=true;
        this.loginDetails.isSupervisor=true;

    }
    this.EmailDuplicate(this.loginDetails.email);
    this.english = 'true';
    this.arabic = localStorage.getItem('Arabic');

}
}
</script>
